import React from 'react';
import Text from 'ui/elements/Text';
import _get from 'lodash/get';

import * as S from './styles';
import RenderBlockText from '../RenderBlockText'

const Box = ({
  box,
  index,
}) => {
  const img = _get(box, 'image');
  const icon = _get(box, 'icon.asset.url');
  const variant = img ? 'image' : 'clean';

  const hasContent = box.content && box.content.length > 0

  return (
    <S.Box variant={variant}>
      <S.Header>
        {
          img && (
            <S.Image
              fluidOptions={{ maxWidth: 1200 }}
              asset={img.asset}
              hotspot={img.hotspot}
              crop={img.crop}
            />
          )
        }
        <S.Icon icon={icon} />
        <S.Title hasImage={!!img}>
          <Text color='shade'>{index}.</Text>
          <Text color='otherGamma'>{box.title}</Text>
        </S.Title>

      </S.Header>
      <S.Content>
        {!hasContent && box.text && <p>{box.text}</p>}
        {hasContent && <RenderBlockText blocks={box.content} />}
      </S.Content>
    </S.Box>
  );
};

export default Box;
