import React from 'react';

import * as S from './styles';
import Box from './Box';
import { Widget } from '../styles';

const NumberedBoxes = ({
  node,
}) => {

  return (
    <Widget>
      <S.NumberedBoxes>
        {
          node.list.map((box, i) => (
            <Box box={box} index={i + 1} key={box._key} />
          ))
        }
      </S.NumberedBoxes>
    </Widget>
  )
};



export default NumberedBoxes;
