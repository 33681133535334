import styled, { css } from 'styled-components';

import ImageWidget from '../Image';
import { breakpoint } from 'ui/elements/ThemeProvider'
import { Text as SText } from 'ui/elements/Text/styles'

const InnerBoxPadding = 25;

export const Icon = styled.span`
  background-color: ${(props) => props.theme.palette.shade};
  background-image: ${(props) => `url(${props.icon})`};
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  
  margin-top: 0;
  margin-bottom: auto;

  flex-shrink: 0;

  &:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }

`;

export const Image = styled(ImageWidget)`
  display: block;
`;

export const Title = styled.div`
  display: flex;
  align-items: flex-start;
  padding: ${InnerBoxPadding}px 0 0 ${InnerBoxPadding}px;
  font-size: 26px;
  line-height: 36px;
  font-weight: bold;
  
  ${props => !props.hasImage ? css`
    padding-bottom: ${InnerBoxPadding}px;
  ` : css`
    padding-right: ${InnerBoxPadding}px;
    padding-bottom: 10px;
  `}

  ${SText} {
    &:first-child {
      margin-right: 5px;
    }
  }
`

export const Header = styled.header`
  display: flex;
  margin: -${InnerBoxPadding}px -${InnerBoxPadding}px 0 -${InnerBoxPadding}px;
`;

export const Box = styled.div`
  position: relative;
  padding: ${InnerBoxPadding}px;
  background: rgb(241, 241, 241);
  font-size: 1.4rem;

  ${(props) => props.variant === 'image' && css`
    ${Icon} {
      position: absolute;
      left: 0;
      top: 0;
      width: 100px;
    }
    
    ${Header} {
      flex-direction: column;
    }
  `}
  
  ${(props) => props.variant === 'clean' && css`
    ${Icon} {
      margin-right: 20px;
      width: 120px;
    }
    
    ${Header} {
      align-items: center;
      margin: -${InnerBoxPadding}px -${InnerBoxPadding}px 0 -${InnerBoxPadding}px;
      padding-right: ${InnerBoxPadding}px;
    }
  `}
`;

export const Content = styled.div`
  margin-top: 15px;
  font-size: 15px;
`;

export const NumberedBoxes = styled.div`
  display: grid;
  grid-gap: 30px;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  
  ${breakpoint.md`
    grid-template-columns: repeat(6, 1fr);
    
    ${Box}:nth-child(8n+1),
    ${Box}:nth-child(8n+6) {
      grid-column: 1 / span 3;
    }
     
    ${Box}:nth-child(8n+2),
    ${Box}:nth-child(8n+7) {
      grid-column: 4 / span 3;
    }
    
    ${Box}:nth-child(8n+3),
    ${Box}:nth-child(8n+4),
    ${Box}:nth-child(8n+5){
      grid-column: 1 / span 6;
    }
    
    ${Box}:nth-child(8n+8) {
      grid-column: 1 / span 6;
    }
  `};
  
  ${breakpoint.lg`
    ${Box}:nth-child(8n+3) {
      grid-column: 1 / span 2;
    }
    
    ${Box}:nth-child(8n+4) {
      grid-column: 3 / span 2;
    }
    
    ${Box}:nth-child(8n+5) {
      grid-column: 5 / span 2;
    }  
  `}
  
`;
