import React from 'react';
import PropTypes from 'prop-types';

import * as S from './styles';
import { AvailableColors } from '../ThemeProvider';

const Text = ({
  children,
  color,
  colorLink,
  className,
  weight,
}) => {
  return (
    <S.Text
      as={`span`}
      color={color}
      colorLink={colorLink}
      className={className}
      weight={weight}
    >
      {children}
    </S.Text>
  )
};

Text.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.oneOf(AvailableColors),
  className: PropTypes.string,
  colorLink: PropTypes.bool,
  weight: PropTypes.oneOf([
    100, 200, 300, 400, 500, 600, 700, 800, 900,
    'inherit', 'lighter', 'normal', 'bold', 'bolder'
  ]),
};

Text.defaultProps = {
  color: 'inherit',
  colorLink: true,
  weight: 'inherit',
};

export { S as TextStyles };
export default Text;
